<template>
    <Transition name="fade">
        <p class="nv" v-if="cond">{{ msg }}</p>
    </Transition>
</template>
<script>
export default {
    name: 'FormAlert',
    props: {
        cond: Boolean,
        msg: String
    }
}
</script>
<style>
.nv {
    color: red;
}
</style>