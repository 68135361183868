<template>
  <b-container style="margin:0;padding:2em 1.3em;max-width:100%;min-height:100vh;">
    <b-spinner v-if="loading" variant="warning" />
    <div v-else class="content" style="max-width: 768px; margin:0 auto;">
      <Transition name="fade">
        <b-row v-if="!user">
          <b-col sm="12">
            <h2 class="mb-3">Preden nadaljuješ, vnesi svojo kodo:</h2>
            <b-form-input v-model="koda" type="text" class="mb-3" placeholder="vnesi kodo..." />
            <form-alert :cond="authFail" msg="Vnesel si napačno kodo. Poskusi znova." />
            <form-alert :cond="authError" msg="Napaka na strežniku. Poskusi znova kasneje ali pokliči Krisa." />
            <b-button @click="doAuth()" :disabled="!koda" variant="light">Prijava</b-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col sm="12" class="mb-3"><h2>Hej, {{ user.username }}!</h2></b-col>
          <Transition name="fade">
            <b-col sm="12" class="mb-4" v-if="!uploaded">
              <h3>Tukaj naloži nov video:</h3>
              <Multiselect :options="profiles" v-model="profile" :searchable="true" placeholder="vnesi ime profila..." />
              <input type="file" accept=".mp4" class="form-control mt-2 mb-2" @change="handleFileUpload( $event )">
              <b-progress :value="uploadPercent" :max="100" show-progress class="mb-3" variant="warning" />
              <p class="text-center" v-if="errMsg" style="color:red;">{{ errMsg }}</p>
              <b-button @click="uploadFile()" :disabled="!file || !profile" variant="light">Naloži</b-button>
            </b-col>
            <b-col sm="12" class="mb-4" v-else>
              <h3>Video je bil uspešno naložen! &#127871;</h3>
              <h4>Povezava je:</h4>
              <p style="overflow-wrap: break-word;">{{ videoBaseUrl }}{{ uploaded.filename }}</p>
              <b-button @click="copyUploadUrl()" class="mb-2" variant="light">
                <span v-if="!copied">Kopiraj jo &#128279;</span>
                <span v-else>Skopirana &#9989;</span>
              </b-button>
              <h4>in/ali</h4>
              <b-button @click="newUpload()" variant="light">Naloži novega &#127909;</b-button>
            </b-col>
          </Transition>
          <b-col sm="12">
            <h3>Tvoji nedavno naloženi videi:</h3>
            <div class="item" v-for="item in items" :key="item.file_id">
              <h4>{{ item.upload_name ? item.upload_name + ' @ ' : '' }}{{ idToProfile(item.profile_id) }}
                <b-button class="ms-2" size="sm" :variant="item.copied ? 'success' : 'light'" @click="copyItemUrl(item)">&#128279;</b-button>
                <b-button class="ms-2" size="sm" variant="light" @click="goToVideo(item)">&#127909;</b-button>
              </h4>
            </div>
          </b-col>
        </b-row>
      </Transition>
    </div>
  </b-container>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import FormAlert from '@/components/FormAlert'
import moment from 'moment'

export default {
  name: 'HomeView',
  components: { Multiselect, FormAlert },
  data() {
    return {
      loading: true,
      user: null,
      koda: '',
      authFail: false,
      authError: false,
      profiles: [],
      profile: null,
      file: null,
      uploadPercent: 0,
      uploaded: null,
      copied: false,
      items: [],
      videoBaseUrl: 'https://dds-uploads.finmaster.si/#/video/',
      errMsg: ''
    }
  },
  async mounted() {
    await this.getProfiles();
    if (this.$cookies.isKey('user')) {
      this.user = this.$cookies.get('user');
      await this.getVideos();
    }
    this.loading = false;
  },
  methods: {
    async doAuth() {
			await this.axios
        .post(process.env.VUE_APP_API_URL + 'uploads/auth', { koda: this.koda })
        .then(response => {
          this.authError = false;
          let res = response.data;
          if (res.success) {
            this.user = res.user;
            this.user.pass = this.koda;
            this.$cookies.set('user', this.user, new Date(2022, 11, 31));
            this.authFail = false;
          } else this.authFail = true;
        })
        .catch(error => console.log(error))
		},
    async getProfiles() {
			await this.axios
        .get(process.env.VUE_APP_API_URL + 'uploads/profiles')
        .then(response => (this.profiles = response.data))
        .catch(error => console.log(error))
		},
    async getVideos() {
      await this.axios
        .get(process.env.VUE_APP_API_URL + 'uploads/user/' + this.user.pass)
        .then(response => (this.items = response.data))
        .catch(error => console.log(error))
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      this.uploadPercent = 0;
      this.errMsg = "";
      let spp = null;
      await this.axios.get(process.env.VUE_APP_API_URL + 'uploads/signedPostPolicy')
        .then(response => (spp = response.data))
        .catch(error => console.log(error))

      let formData = new FormData();
      for (const name of Object.keys(spp.fields)) {
        const value = spp.fields[name];
        formData.append(name, value);
      }
      formData.append('file', this.file);

      await this.axios.post(spp.url, formData, { headers: { 'Content-Type': 'multipart/form-data' }, 
        onUploadProgress: function( progressEvent ) {
          this.uploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this) })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          this.errMsg = "Pri nalaganju videa je prišlo do napake.";
          console.log(error);
        })

      if (this.errMsg) return;
      await this.axios.post(process.env.VUE_APP_API_URL + 'uploads/add',{ 
          filename: spp.fields['key'],
          upload_name: this.file.name.split(/(\\|\/)/g).pop(),
          user_id: this.user.user_id,
          profile_id: this.profile
        })
        .then(response => {
          this.uploaded = response.data
          this.items.splice(0,0,{
            file_id: this.uploaded.file_id,
            user_id: this.user.user_id,
            profile_id: this.profile,
            filename: spp.fields['key'],
            upload_name: this.file.name.split(/(\\|\/)/g).pop(),
            uploaded: moment().toJSON()
          })
        })
        .catch(error => {
          this.errMsg = "Pri nalaganju videa je prišlo do napake.";
          console.log(error);
        })
    },
    copyUploadUrl() {
      let url = this.videoBaseUrl + this.uploaded.filename;
      this.$copyText(url).then(() => this.copied = true);
    },
    newUpload() {
      this.file = null;
      this.uploaded = null;
      this.copied = false;
      this.uploadPercent = 0;
    },
    formatedDate(date,format) {
      return moment(date).format(format);
    },
    idToProfile(id) {
      for (let i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].value == id) return this.profiles[i].label;
      }
      return "";
    },
    copyItemUrl(item) {
      let url = this.videoBaseUrl + item.filename;
      this.$copyText(url).then(() => item.copied = true);
    },
    goToVideo(item) {
      this.$router.push({ name: 'video', params: { id: item.filename }});
    }
  }
}
</script>
<style>
.item>h4 {
  font-size: 0.9rem;
}
.multiselect-option, .multiselect-single-label {
  color: #000;
}
.multiselect-option.is-selected, .multiselect-option.is-selected.is-pointed {
  background: #FFCA05;
}
</style>
